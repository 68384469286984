<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Nhập linh kiện'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <span>
                        <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file
                        excel đúng format, hoặc có thể tải ở
                        <a :href="urlExcel">đây</a>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span> <b>Bước 2:</b> Tiến hành Import </span>
                    <div style="padding-top: 15px">
                      <b-form-file
                        placeholder="Hãy chọn một tập tin"
                        @change="selectFile"
                        v-model="file"
                        ref="file-input"
                        class="mb-2"
                      ></b-form-file>
                    </div>
                    <b-progress :value="progress" :max="100" show-progress>
                      <b-progress-bar
                        :value="progress"
                        :label="`${progress.toFixed(2)}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </b-col>
                </b-row>
              </b-container>
              <!-- End input session -->

              <b-container class="mt-10" v-show="importItems.length > 0">
                <h5>Danh sách linh kiện từ file excel</h5>
                <b-table
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fields"
                  :items="importItems"
                  id="my-table"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(price)="row">
                    <div style="text-align: end">
                      <span v-text="convertPrice(row.item.price)"></span>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                  align="right"
                ></b-pagination>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <div class="bv-example-row container">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="importProductComponent"
                  >Lưu</b-button
                >
                <router-link to="/product-component" tag="button">
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    >Hủy</b-button
                  >
                </router-link>
              </b-row>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  color: #464e5f;
  background-color: transparent;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import xlsx from 'xlsx';
import ApiService from '@/core/services/api.service';
import { URL_IMPORT_PRODUCT_COMPONENT } from './../../../utils/constants';

export default {
  data() {
    return {
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên linh kiện',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'code',
          label: 'Mã linh kiện',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productComponentCategoryName',
          label: 'Danh mục linh kiện',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productComponentGroupName',
          label: 'Nhóm sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'price',
          label: 'Giá',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-right',
        },
      ],
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      currentPage: 1,
      urlExcel: URL_IMPORT_PRODUCT_COMPONENT,
    };
  },
  components: {
    KTCodePreview,
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Linh kiện', route: '/product-component' },
      { title: 'Danh sách linh kiện', route: '/product-component' },
      { title: 'Nhập linh kiện' },
    ]);
  },
  methods: {
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },

    importProductComponent: async function () {
      this.uploading = true;
      if (!this.file) {
        return;
      }
      let data = {
        listData: this.importItems,
      };
      ApiService.setHeader();
      ApiService.post('productComponent/import', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.clearFiles();
            this.$router.push({
              name: 'list-product-component',
            });
          } else {
            this.makeToastFaile(message);
          }
          this.uploading = false;
        })
        .catch((e) => {
          this.makeToastFaile(e);
          this.uploading = false;
        });
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element, index) => {
            let data = {
              count: index + 1,
              name: element['Tên linh kiện']
                ? element['Tên linh kiện'].trim()
                : '',
              code: element['Mã linh kiện']
                ? element['Mã linh kiện'].trim()
                : '',
              productComponentCategoryName: element['Danh mục linh kiện']
                ? element['Danh mục linh kiện'].trim()
                : '',
              productComponentGroupName: element['Nhóm sản phẩm linh kiện']
                ? element['Nhóm sản phẩm linh kiện'].trim()
                : '',
              price: element['Giá'] ? element['Giá'] : 0,
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>
